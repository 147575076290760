import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import { BookMeeting } from "../../components/BookMeeting";
import { Container } from "../../components/Container";
import SearchCards from "./SearchCards";

const banner = css`
  ${tw`flex items-center justify-center max-w-prose mx-auto text-center`}
  h1 {
    ${tw`text-4xl sm:text-7xl leading-none font-black mt-8`}
  }
`;

const Banner: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <Container {...rest}>
      <article css={banner}>
        <div tw="text-center">
          <h1 tw="mt-8">
            {t("hero.header")}
            <br />
            {t("hero.skyRocket")}
          </h1>
          <p tw="text-xl sm:text-2xl mt-2 mb-2">{t("hero.subheader")}</p>
          <SearchCards />
          <h2 tw="mb-8 mt-2">{t("hero.processOnDemand")}</h2>
          <BookMeeting commentFirst={true} />
          <br />
        </div>
      </article>
    </Container>
  );
};

export default Banner;
